import medal1 from "./icons/medal.png";
import second from "./icons/second.png";
import award from "./icons/award.png";
import third from "./icons/third.png";
import fischer from "./icons/fischer.png";
import bulletbill from "./icons/bulletbill.png";
import shark from "./icons/shark.png";
import pilopp from "./icons/top-right.png";
import ti from "./icons/10.png";
import tjue from "./icons/20.png";
import tretti from "./icons/30.png";
import femti from "./icons/50.png";
import hundre from "./icons/100.png";
import slingshot from "./icons/slingshot.png";
import boxer from "./icons/boxer.png";
import arm from "./icons/robot-arm.png";
import gepard from "./icons/cheetah.png";
import orangutan from "./icons/orangutan.png";
import gg from "./icons/gg.png";
import kortrokade from "./icons/kortrokade.png";
import batman_begins from "./icons/batman_begins.png";
import spaghetti from "./icons/spaghetti.png";
import rogue from "./icons/rogue.png";
import fox from "./icons/fox.png";
import promotion from "./icons/promotion.png";
import tdkr from "./icons/dark_knight_rises.png";
import knightmate from "./icons/knightmate.png";
import bishopknight from "./icons/bishopknight.png";
import point from "./icons/point.png";
import strat from "./icons/strat.png";
import hugh from "./icons/hugh.png";
import horse from "./icons/horse.png";
import french from "./icons/french.png";
import sangria from "./icons/sangria.png";
import queen from "./icons/queen.png";
import dominance from "./icons/dominance.png";
import composer from "./icons/composer.png";
import god from "./icons/god.png";
import baby from "./icons/baby.png";
import gettingthere from "./icons/gettingthere.png";
import growing from "./icons/growing.png";
import talking from "./icons/chat.png";
import cashing from "./icons/cashing.png";
import troll from "./icons/troll.png";
import cross from "./icons/cross.png";
import keeper from "./icons/keeper.png";
import gandalf from "./icons/gandalf.png";
import pusheen from "./icons/pusheen.png";
import two from "./icons/two.png";
import three from "./icons/three.png";
import four from "./icons/four.png";
import five from "./icons/five.png";
import stroller from "./icons/stroller.png";
import bishop from "./icons/bishop.png";
import halloween from "./icons/halloween.png";
import walk from "./icons/walk.png";
import twins from "./icons/twins.png";
import speed from "./icons/speed.png";
import risk from "./icons/caution.png";
import gaussian from "./icons/gaussian.png";
import tree from "./icons/tree.png";
import five_tournaments from "./icons/high-five.png";

export default [
  {
    title: "STARTED",
    image: medal1,
    description: "Play one tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.tournaments?.length;
    },
    xp: 10,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "TREE",
    image: tree,
    description: "Play three tournaments",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.tournaments?.length >= 3;
    },
    xp: 50,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "HIGH FIVE",
    image: five_tournaments,
    description: "Play five tournaments",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.tournaments?.length >= 5;
    },
    xp: 200,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "GOLD",
    image: medal1,
    description: "Win a tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.firstPlaces;
    },
    xp: 800,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "SILVER",
    image: second,
    description: "Finish second",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.secondPlaces;
    },
    xp: 500,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BRONZE",
    image: third,
    description: "Finish third",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.thirdPlaces;
    },
    xp: 300,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "10%",
    image: gaussian,
    description: "Finish within the top tenth",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.thirdPlaces;
    },
    xp: 300,
    lvlReq: 3,
    category: "tournament",
    permanent: false,
  },
  {
    title: "MASTER",
    image: award,
    description: "Finish among the top 3 in 5 tournaments",
    secret: false,
    fr_specific: false,
    check: function (player) {
      const pallPlasseringer =
        player.firstPlaces + player.secondPlaces + player.thirdPlaces;
      return pallPlasseringer > 4;
    },
    xp: 2000,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "UNDERDOG",
    image: slingshot,
    description: "Beat a higher rated player",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.beaten_higher_rated;
    },
    xp: 50,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "SHARK",
    image: shark,
    description: "Win a Blitz tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.filter(
        (t) => t.key === "blitz" && t.rank === 1
      ).length;
    },
    xp: 1000,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BULLET BILL",
    image: bulletbill,
    description: "Win a Bullet tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find(
        (t) => t.key === "bullet" && t.rank === 1
      );
    },
    xp: 1000,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "FISCHER",
    image: fischer,
    description: "Vinn en Fischersjakkturnering.",
    secret: false,
    fr_specific: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find(
        (t) => t.key === "chess960" && t.rank === 1
      );
    },
    xp: 300,
    lvlReq: 3,
    permanent: false,
  },
  {
    title: "PERFORMANCE",
    image: pilopp,
    description: "Perform higher than your rating in a tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.performance - t.rating > 0);
    },
    xp: 250,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "TEN",
    image: ti,
    description: "Get at least 10 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 9);
    },
    xp: 20,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "TWENTY",
    image: tjue,
    description: "Get at least 20 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 19);
    },
    xp: 20,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "THIRTY",
    image: tretti,
    description: "Get at least 30 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 29);
    },
    xp: 90,
    lvlReq: 2,
    permanent: true,
  },
  {
    title: "FIFTY",
    image: femti,
    description: "Get at least 50 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 49);
    },
    xp: 300,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "HUNDRED",
    image: hundre,
    description: "Get at least 100 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 99);
    },
    xp: 500,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "TITLEBREAKER",
    image: boxer,
    description: "Beat a titled player.",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_titled;
    },
    xp: 800,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "ORANGUTAN",
    image: orangutan,
    description: "Play 1. b4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_b4;
    },
    xp: 80,
    lvlReq: 1,
    permanent: false,
  },
  {
    title: "FIANCHETTO",
    image: bishop,
    description: "Play both bishops to b2 and g2 within the first 4 moves.",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.fianchetto;
    },
    xp: 80,
    lvlReq: 1,
    permanent: false,
  },
  {
    title: "GG",
    image: gg,
    description: "Play 1. g4",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_g4;
    },
    xp: 80,
    lvlReq: 1,
    permanent: false,
  },
  {
    title: "THERE...",
    image: walk,
    description: "Reach the other side of the board with your King (and win).",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.king_reached_last_row;
    },
    xp: 210,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "SPEED",
    image: speed,
    description:
      "Spend less than 2 seconds per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 2;
    },
    xp: 180,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "SPEED 2",
    image: speed,
    description:
      "Spend less than one second per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 1;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "NEED FOR SPEED",
    image: speed,
    description:
      "Spend less than half a second per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 0.5;
    },
    xp: 600,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "RISKY",
    image: risk,
    description: "5 premoves in a row",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.longest_premoves_in_row > 4;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "HALLOWEEN",
    image: halloween,
    description: "Win with the Halloween Gambit",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_halloween;
    },
    xp: 350,
    permanent: false,
  },
  {
    title: "WOW",
    image: arm,
    description: "Beat a player rated at least 300 points higher than you",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_300_higher_rated;
    },
    lvlReq: 5,
    xp: 300,
    permanent: true,
  },
  {
    title: "CHEETAH",
    image: gepard,
    description: "Play 40+ games in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.flest_partier_paa_en_turnering > 39;
    },
    lvlReq: 5,
    xp: 1000,
    permanent: true,
  },
  {
    title: "PLAYER",
    image: rogue,
    description: "Play 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 99;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "FOX",
    image: fox,
    description: "Play 300 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 299;
    },
    lvlReq: 5,
    xp: 800,
    permanent: true,
  },
  {
    title: "PROMOTION",
    image: promotion,
    description: "Promote at least 4 pawns during one game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_number_of_promotions > 3;
    },
    lvlReq: 5,
    xp: 700,
    permanent: false,
  },
  {
    title: "KNIGHTMARE",
    image: knightmate,
    description: "Promote to knight with mate",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.wowowK;
    },
    lvlReq: 10,
    xp: 2000,
    permanent: true,
  },
  {
    title: "TEAMWORK",
    image: bishopknight,
    description: "Mate with bishop and knight",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.mate_with_bishop_and_knight;
    },
    lvlReq: 7,
    xp: 1200,
    permanent: true,
  },
  {
    title: "TO THE POINT",
    image: point,
    description: "Play 1. e4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "STRATEGIST",
    image: strat,
    description: "Play 1. d4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_d4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "HUGH GRANT",
    image: hugh,
    description: "Play 1. c4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "THE HORSE WHISPERER",
    image: horse,
    description: "Play 1. Nf3",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nf3;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "THE SHORTCUT",
    image: french,
    description: "Play the French",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e6;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "SANGRIA",
    image: sangria,
    description: "Play the Spanish",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_spanish;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: "MOM'S SPAGHETTI",
    image: spaghetti,
    description: "Play the Italian",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_italian;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: 'THE "GAMBIT"',
    image: queen,
    description: "Play the Queen's Gambit",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_qg;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: "COMPOSER",
    image: composer,
    description: "Play the Philidor Defence",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_philidor;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "SHORT AND SWEET",
    image: kortrokade,
    description: "Castle at move 4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.short_castle_4;
    },
    lvlReq: 2,
    xp: 60,
    permanent: false,
  },
  {
    title: "GREEK GOD",
    image: god,
    description: "Win with The Greek (1. f3 2. Kf2)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_greek;
    },
    lvlReq: 7,
    xp: 300,
    permanent: false,
  },
  {
    title: "BABY STEPS",
    image: baby,
    description: "Win a game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 0;
    },
    lvlReq: 1,
    xp: 30,
    permanent: true,
  },
  {
    title: "GROWING",
    image: growing,
    description: "Win 10 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 9;
    },
    lvlReq: 2,
    xp: 70,
    permanent: true,
  },
  {
    title: "GETTING THERE",
    image: gettingthere,
    description: "Win 30 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 29;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "NOW WE'RE TALKING",
    image: talking,
    description: "Win 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 99;
    },
    lvlReq: 5,
    xp: 500,
    permanent: true,
  },
  {
    title: "CASHING IN",
    image: cashing,
    description: "Win 250 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 249;
    },
    lvlReq: 7,
    xp: 1000,
    permanent: true,
  },
  {
    title: "LEFONG",
    image: troll,
    description: "Win after a succeeded Lefong",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.lefong;
    },
    lvlReq: 7,
    xp: 400,
    permanent: false,
  },
  {
    title: "GOOD LUCK",
    image: cross,
    description: "Play Magnus (DrNykterstein)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_magnus;
    },
    lvlReq: 1,
    xp: 80,
    permanent: true,
  },
  {
    title: "GANDALF",
    image: gandalf,
    description: "Play Andrew Tang (penguingm1)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_penguin;
    },
    lvlReq: 1,
    xp: 50,
    permanent: true,
  },
  {
    title: "MEOW",
    image: pusheen,
    description: "Play PusheenMeow",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_pusheen;
    },
    lvlReq: 1,
    xp: 50,
    permanent: true,
  },
  {
    title: "KEEPER",
    image: keeper,
    description: "Keep at least 13 pieces (and win)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_own_pieces_taken < 4;
    },
    lvlReq: 3,
    xp: 250,
    permanent: true,
  },
  {
    title: "STREAK",
    image: two,
    description: "Win 2 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 1;
    },
    lvlReq: 2,
    xp: 60,
    permanent: true,
  },
  {
    title: "STREEAK",
    image: three,
    description: "Win 3 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 2;
    },
    lvlReq: 3,
    xp: 200,
    permanent: true,
  },
  {
    title: "STREEEAK",
    image: four,
    description: "Win 4 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 3;
    },
    lvlReq: 5,
    xp: 500,
    permanent: true,
  },
  {
    title: "STREEEEAK",
    image: five,
    description: "Win 5 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 4;
    },
    lvlReq: 7,
    xp: 800,
    permanent: true,
  },
  {
    title: "ADOPTION",
    image: stroller,
    description: "Win 10 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 9;
    },
    lvlReq: 10,
    xp: 1000,
    permanent: true,
  },
  {
    title: "TWINS",
    image: twins,
    description: "Win 20 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 9;
    },
    lvlReq: 10,
    xp: 2000,
    permanent: true,
  },
  {
    title: "BATMAN BEGINS",
    image: batman_begins,
    description: "Play the Alekhine's Defence",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_alekhine;
    },
    lvlReq: 7,
    xp: 80,
    permanent: false,
  },
  {
    title: "THE DARK KNIGHT RISES",
    image: tdkr,
    description: "After 1. e4, play 1... Nf6 and 2... Nc6 (and win).",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tdkr;
    },
    lvlReq: 7,
    xp: 300,
    permanent: false,
  },
  {
    title: "DOMINANCE",
    image: dominance,
    description: "Win without losing a single piece",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_own_pieces_taken === 0;
    },
    lvlReq: 7,
    xp: 300,
    permanent: false,
  },
];
